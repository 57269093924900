@import "../sass/cartus-palette.scss";

.dialog-main {
  // Dialog title header
  .mat-dialog-title {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    gap: 1.25em;
    @media screen and (max-width: 599px) {
      gap: 0;
    }
  }
  .dialog-title {
    font-family: "Avenir-Roman", sans-serif;
    font-size: 1.5em;
    color: map-get($cartus-palette, "cartus_gray");
    font-weight: 100;
  }
  // Dialog input
  input {
    color: map-get($cartus-palette, "cartus_gray");
    font-weight: 100;
    font-family: "Avenir-Book", sans-serif;
    line-height: 1.0625em;
    &:read-only {
      color: #000000;
      opacity: 0.38;
    }
  }
  .mat-select-disabled {
    cursor: not-allowed;
  }
  // Dialog form fields
  .mat-form-field,
  .mat-select {
    width: 100%;
    font-family: "Avenir-Book", sans-serif;
    line-height: 1.5625em;
  }
  // Dialog container
  .mat-dialog-container {
    border-radius: 0.625em;
    padding: 1em;
    @media screen and (max-width: 767px) {
      overflow: hidden;
      width: 36em;
      .dialog-form-container {
        flex-direction: column;
        min-height: 100%;
      }
    }
    @media screen and (min-width: 600px) {
      .dialog-form-container {
        min-height: 16em;
        overflow: hidden;
      }
      overflow: hidden;
    }
    @media screen and (min-width: 768px) and (max-width: 1199px) {
      width: 54em;
    }
    @media screen and (min-width: 1200px) {
      width: 70em;
    }
    .dialog-form-container {
      display: flex;
      flex-direction: column;
      form {
        display: flex;
        flex-direction: column;
        flex: 1 0 auto;
        .dialog-middle-section {
          flex: 1 0 auto;
        }
      }
    }
  }
  // Dialog content
  .mat-dialog-content {
    padding: 1em;
    font-family: "Avenir-Roman", sans-serif;
    font-size: 1em;
    color: map-get($cartus-palette, "cartus-gray");
    line-height: 1.5625em;
    .mat-icon {
      color: map-get($cartus-palette, "cartus_dark_accent_blue");
    }
    p:first-of-type {
      margin: 0em;
    }
    @media screen and (min-width: 768px) {
      overflow-y: auto;
      overflow-x: hidden;
    }
    .row {
      display: flex;
      flex-direction: row;
      align-items: stretch;
      justify-content: space-between;
      gap: 2em;
      margin-bottom: 1em;
      @media screen and (max-width: 599px) {
        gap: 1em;
        flex-direction: column;
      }
      .col50 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex: 1 1 50%;
        @media screen and (max-width: 599px) {
          flex: 1 1 100%;
          flex-direction: column;
        }
      }
      .col100 {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        flex: 1 1 100%;
      }
      
    }
  }
  // Dialog action buttons
  .mat-dialog-actions {
    margin: 0;
    padding: 0;
    justify-content: flex-end;
    align-items: flex-end;
    gap: 1em;
    @media screen and (max-width: 599px) {
      gap: 0.5em;
    }
    button {
      margin: 0;
    }
  }
  // Dialog small screen
  @media screen and (max-width: 599px) {
    max-width: 100% !important;
    height: 100%;
    width: 100%;
    .mat-dialog-container {
      border-radius: 0;
      width: 100%;
      box-shadow: none;
      border: none;
    }
  }
}
