@import '../sass/cartus-palette.scss';

// Teams

._team_button_default {
  font-family: 'Avenir-Roman', sans-serif;
  font-size: 1em;
  line-height: 1em;
  color: map-get($cartus-palette, 'cartus_accent_blue');
  height: 2.4em;
  box-sizing: border-box;
  padding: 0.4em 0.8em;
  margin-bottom: 0.4em;
  border-radius: 4px;
}

._team_button_focused {
  background-color: map-get($cartus-palette, 'cartus_dark_accent_blue');
  color: map-get($cartus-palette, 'cartus_nav_tile');
  border: 1px solid map-get($cartus-palette, 'cartus_dark_accent_blue');
  .mat-button-wrapper {
    span {
      text-decoration: underline;
    }
  }
}

._team_button_disabled {
  background-color: map-get($cartus-palette, 'cartus_disabled_gray') !important;
  color: map-get($cartus-palette, 'cartus_light_gray') !important;
  border-color: map-get($cartus-palette, 'cartus_disabled_gray');
}

button {
  &:disabled {
    cursor: not-allowed;
    pointer-events: none;
  }
  &.button-sm {
    @extend ._team_button_default;
    font-size: 1em;
    background-color: map-get($cartus-palette, 'cartus_accent_blue');
    color: map-get($cartus-palette, 'cartus_nav_tile');
    border: 1px solid map-get($cartus-palette, 'cartus_accent_blue');
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      @extend ._team_button_focused;
    }
    &:hover,
    &:focus {
      @extend ._team_button_focused;
    }
    &:disabled {
      @extend ._team_button_disabled
    }
    span.mat-button-wrapper .mat-icon {
      margin-right: 0.25em;
      color: map-get($cartus-palette, 'cartus_nav_tile');
      .mat-spinner circle {
        stroke: map-get($cartus-palette, 'cartus_nav_tile') !important;
      }
    }
  }
  &.button-outline-sm {
    @extend ._team_button_default;
    font-size: 1em;
    background-color: map-get($cartus-palette, 'cartus_nav_tile');
    color: map-get($cartus-palette, 'cartus_accent_blue');
    border: 1px solid map-get($cartus-palette, 'cartus_accent_blue');
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      @extend ._team_button_focused;
    }
    &:hover,
    &:focus {
      @extend ._team_button_focused;
      span.mat-button-wrapper .mat-icon {
        color: map-get($cartus-palette, 'cartus_nav_tile');
      }
    }
    &:disabled {
      @extend ._team_button_disabled;
      span.mat-button-wrapper .mat-icon {
        color: map-get($cartus-palette, 'cartus_nav_tile');
      }
    }
    span.mat-button-wrapper .mat-icon {
      margin-right: 0.25em;
      color: map-get($cartus-palette, 'cartus_accent_blue');
    }
  }
  &.button-xs {
    @extend ._team_button_default;
    font-size: 0.75em;
    color: map-get($cartus-palette, 'cartus_nav_tile');
    background-color: map-get($cartus-palette, 'cartus_accent_blue');
    border: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &:active {
      @extend ._team_button_focused;
      background-color: map-get($cartus-palette, 'cartus_accent_blue');
    }
    &:hover,
    &:focus {
      background-color: map-get($cartus-palette, 'cartus_dark_accent_blue');
      color: map-get($cartus-palette, 'cartus_nav_tile');
    }
    &:disabled {
      color: map-get($cartus-palette, 'cartus_light_gray') !important;
      background-color: map-get($cartus-palette, 'cartus_disabled_gray') !important;
    }
    span.mat-button-wrapper .mat-icon {
      margin-right: 0.25em;
      color: map-get($cartus-palette, 'cartus_nav_tile');
      width: 1em;
      height: 1em;
      line-height: 1em;
      font-size: 1em;
    }
  }
  &:focus {
    outline: 1px dotted transparent !important;
  }
}

.button-icon {
  font-size: 2em;
  padding: 0.1em;
  margin: 0 0 0 0.25em;
  border: none;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: center;
  .mat-icon {
    color: map-get($cartus-palette, 'cartus_light_gray');
  }
  &:hover,
  &:focus {
    background-color: map-get($cartus-palette, 'cartus_hover_tint');
    .mat-icon {
      color: map-get($cartus-palette, 'cartus_light_gray');
    }
    cursor: pointer;
  }
  &.active {
    background-color: map-get($cartus-palette, 'cartus_dark_accent_blue');
    .mat-icon {
      color: map-get($cartus-palette, 'cartus_nav_tile');
    }
    &:hover {
      background-color: map-get($cartus-palette, 'cartus_hover_tint');
      .mat-icon {
        color: map-get($cartus-palette, 'cartus_light_gray');
      }
      cursor: pointer;
    }
  }
}

.button-icon-xl {
  @extend .button-icon;
  .mat-icon {
    font-size: 2em;
    display: flex;
    align-items: center;
    color: #0059CB !important;
    width: 64px;
    height: 72px;
    padding: 0 0.125em;
  }
  &:disabled {
    .mat-icon {
      color: map-get($cartus-palette, 'cartus_disabled_gray') !important;
    }
  }
}

// RoleCaps

.action-button {
  width: 2.5em;
  height: 2.5em;
  box-shadow: 0 0.25em 0.5em rgba(0, 0, 0, 0.05);
  background-color: map-get($cartus-palette, "cartus_nav_tile");
  border: 0.0625em solid map-get($cartus-palette, 'cartus_seconday_off_white');
  border-radius: 0.5em !important;
  opacity: 1;
  color: map-get($cartus-palette, 'cartus_toolbar_blue') !important;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    background-color: map-get($cartus-palette, 'cartus_hover_tint');
  }
  &:disabled {
    .mat-icon {
      color: map-get($cartus-palette, 'cartus_disabled_gray') !important;
    }
  }
}