@import '../sass/cartus-palette.scss';
.snack-container {
    width: 100%;
  }
.mat-snack-bar-container {
    box-sizing: border-box;
    display: flex;
    // margin: 0.875em;
    margin: 1.5em auto;
    // max-width: max-content !important;
    max-width: 100% !important;
    min-width: 10.375em !important;
    padding: 0.125em 0.5em;
    min-height: 3em;
    transform-origin: center;
    justify-content: center;
    align-items: center;
    background: map-get($cartus-palette, 'cartus_gray') 0% 0% no-repeat padding-box;
    box-shadow: 0px 8px 8px rgba(0, 0, 0, 0.251);
    border-radius: 4px;
    font-size: 1em;
    font-family: 'Avenir-Heavy', sans-serif;
  }
  @media screen and (max-width: 1024px) {
    .mat-snack-bar-handset .mat-snack-bar-container {
      max-width: max-content !important;
      display: flex;
      align-items: center;
      justify-content: center;
      // margin: 0 auto !important;
      margin: 1.5em auto !important;
    }
  }
.snack-icons{
    padding-left:.5em;
    display:flex;  
    position: relative;
    a.close .mat-icon{
        width:3em;
        height:3em;   
        font-size:1.2em !important; 
    }
    a.save .mat-icon{
        width:3em;
        height:3em;  
        font-size:1.2em !important;  
    }
    .mat-icon{      
        display:flex;
        align-items:center;
        justify-content: center;      
        font-size:1.6em !important; 
        cursor: pointer;
        &.loader-close-icon{
            position: relative;
            font-size: 0.75em !important;
        }
    }
 
}


//Spinner css
.spinner-container{
    position: absolute;
    right: 7%;
    top: 0.1em;
      @media screen and (min-width:768px) and (max-width:1024px){
        position: absolute;
      right: 8%;
      top: 0.18em;
      }
      @media  screen and (max-width:600px){
          right:7%;
      }
  }
  .loader {
      border: .1em solid rgba(255, 255, 255, 255);
      border-radius: 50%;
      width: 1em;
      height: 1em;
      -webkit-animation: spin 6s ease-out infinite; /* Safari */
      animation: spin 6s ease-out infinite;
      transform: rotate(45deg);
    }
  
  
  @keyframes spin {
      25% {
      border-top: .1em solid map-get($cartus-palette, 'cartus_gray');
      }
      50% { 
      border-right: .1em solid map-get($cartus-palette, 'cartus_gray');
      }
      75% { 
      border-bottom: .1em solid map-get($cartus-palette, 'cartus_gray');
      }
       90% { 
   border-bottom: .1em solid map-get($cartus-palette, 'cartus_gray');
       }
      95% { 
      border-left: .1em solid map-get($cartus-palette, 'cartus_gray');
      }
      100% { 
      border-top: .1em solid map-get($cartus-palette, 'cartus_gray');
      border-left:.1em solid map-get($cartus-palette, 'cartus_gray');
      border-right: .1em solid map-get($cartus-palette, 'cartus_gray');
      border-bottom: .1em solid map-get($cartus-palette, 'cartus_gray');
      }
    }
    // Spinner ends
